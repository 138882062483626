export const PAGE_TRACKING_NAMES = {
    HomePage: "Home page",
    MySearches: "My searches",
    PropertyValuation: "Property Valuation",
    PropertyForSale: "Property For Sale",
    PropertyForRent: "Property For Rent",
    PropertyForSaleAdminArea: "Property For Sale By Administrative Area",
    PropertyForRentAdminArea: "Property For Rent By Administrative Area",
    SearchForSale: "Search For Sale",
    SearchForRent: "Search For Rent",
    SearchForSaleMaps: "Search For Sale Maps",
    SearchForRentMaps: "Search For Rent Maps",
    Login: "Login",
    Register: "Register",
    ContactUs: "Static Pages - Contact Us",
    DisplayAds: "Static Pages - Display Advertising",
    FAQ: "Static Pages - FAQ",
    Feedback: "Static Pages - Feedback",
    Four0Four: "Four 0 Four",
    HowItWorks: "Static Pages - How It Works",
    ListProperties: "Static Pages - List Properties",
    MobileApp: "Static Pages - Mobile App",
    Press: "Static Pages - Press",
    PrivacyPolicy: "Static Pages - Privacy Policy",
    TermsOfUse: "Static Pages - Terms of Use",
    CommuteTimeSearch: "Commute time search",
    PropertyDetailsForSale: "Property Details For Sale",
    PropertyDetailsForRent: "Property Details For Rent",
    Search: "Search",
    MyAccount: "MyAccount",
    Favourites: "My Favourites",
    ContentSection: "Content Section",
    ContentArticle: "Content Article",
    Enquiries: "My Enquiries",
    AreaGuidesLocalities: "Area guides - Localities",
    AreaGuides: "Area guides",
    AreaGuide: "Area guide"
}

export const TRACKING_EVENTS = {
    RegistrationComplete: "registrationComplete"
}